<div class="p-4 items-center max-w-full">
    <div class="overflow-y-auto">

        <ul class="flex space-x-4" *ngIf="collectionTree$ | async as tree">
            <li class="nav-item" *ngFor="let collection of tree.items"
                (mouseenter)="onMouseEnter(collection)"
                (mouseleave)="close($event)">
                <a class="nav-link whitespace-nowrap"
                    [class.active]="(setActiveCollection$ | async)?.id === collection.id && overlayIsOpen$ | async"
                    (touchstart)="onTopLevelClick($event, collection)"
                    [routerLink]="['/category', collection.slug ]">{{ collection.name }}
                </a>
            </li>
        </ul>
    </div>
</div>
<ng-template #menuTemplate>

    <div class="bg-gradient-to-r from-zinc-600 to-zinc-800 text-white w-full p-4 overflow-y-auto"
         *ngIf="activeCollection?.children?.length"
         (touchstart)="captureTouchStart($event)"
         (mouseenter)="onMouseEnter(activeCollection)"
         (mouseleave)="close()"
    >
    
        <ul class="flex space-x-4">
            <li class="nav-item" *ngFor="let level2 of activeCollection.children">
        
                <a class="nav-link whitespace-nowrap"
                    [routerLink]="['/category', level2.slug]"
                    (click)="close()">
                    {{ level2.name }}
                </a>
            </li>
        </ul>

    </div>

</ng-template>
