import { Component, OnDestroy, OnInit} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

import { PinboardWorkComponent } from '../work/work.component';

import {FormControl, FormGroupDirective, NgForm, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';

import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { GetPinboardQuery, GetPinboardQueryVariables,ProductOption,ProductVariant } from '../../../../common/generated-types';
import { notNullOrUndefined } from '../../../../common/utils/not-null-or-undefined';
import { DataService } from '../../../providers/data/data.service';
import { StateService } from '../../../providers/state/state.service';
import { GET_PINBOARD } from './gallery.graphql';

export interface PinboardData {
  title: string;
  agent: string;
  price:string;
  agentDesc:string,
  dimension: string;
  about: string;
  imageUrl: string;
  nfp:string;
  ntf: string;
  modelUrl:string;
  arQuickLookUrl:string;
  sketchFabId:string;
}


@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})



export class PinboardGalleryComponent implements OnInit, OnDestroy {
  
  collection: GetPinboardQuery['collection'];

  emailFormControl = new FormControl('', [Validators.required, Validators.email]);

  
  private sub: Subscription;

  constructor(
    private dataService: DataService,
    private stateService: StateService,
    private route: ActivatedRoute,
    private dialog: MatDialog) {
  }

  ngOnInit() {

    const lastCollectionSlug$ = this.stateService.select(state => state.lastCollectionSlug);
    const productSlug$ = this.route.paramMap.pipe(
        map(paramMap => paramMap.get('slug')),
        filter(notNullOrUndefined),
    );

    this.sub = productSlug$.pipe(
        switchMap(slug => {
            return this.dataService.query<GetPinboardQuery, GetPinboardQueryVariables>(GET_PINBOARD, {
                    slug,
                },
            );
        }),
        map(data => data.collection),
        filter(notNullOrUndefined),
        withLatestFrom(lastCollectionSlug$),
    ).subscribe(([collection, lastCollectionSlug]) => {
        this.collection = collection;    
        
        console.log( this.collection);
    });

  }

  ngOnDestroy() {
    if (this.sub) {
        this.sub.unsubscribe();
    }
  }

  onClick(item: ProductVariant) {

    var dimension;

    for(let v of item.product.variants as ProductVariant[]) {

      for(let o of v.options as ProductOption[]) {
        
        if(o.group.code == "dimension") {          
          dimension = o.name
          break;
        }
 
      }

      if(dimension) break;
    }


    const dialogRef = this.dialog.open(PinboardWorkComponent, {
      autoFocus: true,
      restoreFocus: true,

      panelClass: "custom",
      data: {title:item.name,
             agent:item.product.customFields?.agent,
             price:item.price,
             agentDesc:item.product.customFields?.agentDesc,
             dimension:dimension,
             about:item.product.description, 
             imageUrl: item.product.featuredAsset?.source,
             modelUrl: item.product.customFields?.modelUrl,
             arQuickLookUrl: item.product.customFields?.arQuickLookUrl,
             sketchFabId: item.product.customFields?.sketchFabId,
             nfp: item.product.customFields?.nfp,
             ntf: item.product.customFields?.ntf,
            },
        disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });


  }



}
