
<mat-card>
    <mat-card-header>

        <div class="assets">
            <mat-tab-group #tabGroup headerPosition="below" mat-stretch-tabs="false" animationDuration="0ms" mat-align-tabs="start"  (selectedTabChange)="tabChanged($event)">

    
                <mat-tab label="Images">
                    <img #image class="images" mat-card-image src="{{ pinboard.imageUrl }}" alt="{{ pinboard.title }}">
                </mat-tab>


                <mat-tab label="Model" *ngIf="pinboard.sketchFabId">

                    <iframe 
                        frameborder="0" 
                        allowfullscreen 
                        class="model-viewer"
                    
                        mozallowfullscreen="true" 
                        webkitallowfullscreen="true" 
                        allow="autoplay; fullscreen; xr-spatial-tracking" 
                        xr-spatial-tracking 
                        execution-while-out-of-viewport 
                        execution-while-not-rendered 
                        web-share

                        id="sketchFabFrame"
                        #sketchFabFrame
                        >
                    </iframe>
                   

                </mat-tab>

                <mat-tab label="Model"  *ngIf="pinboard.modelUrl && !pinboard.sketchFabId">

  
                    <model-viewer
                        src="{{ pinboard.modelUrl }}"
                        class="model-viewer"
                        #modelViewer
                        auto-rotate
                        camera-controls 
                        rotation-per-second="50deg">
                  </model-viewer>

                </mat-tab>
            </mat-tab-group>
        </div>

        <a class="close" mat-icon-button aria-label="close dialog" mat-dialog-close>
            <mat-icon  class="icon">close</mat-icon>
        </a>

    </mat-card-header>

    <mat-card-content class="wrapper">

        <div class="title">Title: <span class="text">{{ pinboard.title }}</span></div> 

        <div *ngIf="pinboard.agent">
            <div class="title">Agent: <span class="text">{{ pinboard.agent }}</span></div>
        </div>

        <div class="title">Price: <span class="text">{{ pinboard.price | formatPrice  }}</span></div>

        <div *ngIf="pinboard.dimension">
            <div class="title">Dimensions: <span class="text">{{ pinboard.dimension }}</span></div>
        </div>
            
        <div class="title">About: <span class="text"  [innerHtml]="pinboard.about"></span></div>

        <div *ngIf="pinboard.agent">
            <div class="title">Agent's Description: <span class="text" [innerHtml]="pinboard.agentDesc"></span></div>
        </div>

        <div class="title">NFP included: <span class="text">{{ pinboard.nfp }}</span></div>

        <div class="title">NTF available: <span class="text">{{ pinboard.ntf }}</span></div>
    </mat-card-content>

    <div class="nav">

        <button mat-stroked-button class="nav__rounded" disabled="true">Buy</button>
        <vsf-ar-button src="{{ pinboard.modelUrl }}" iosSrc="{{ pinboard.arQuickLookUrl }}" sketchFabId="{{ pinboard.sketchFabId }}" fallbackUrl="" (sketchFabEvent)="startSketchFabAR()"></vsf-ar-button>
        <button mat-stroked-button class="nav__rounded" disabled="true">Reserve</button>
        <a #startAR id="startAR"></a>

    </div>

</mat-card>
