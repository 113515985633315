import { ChangeDetectionStrategy, EventEmitter, Component, Output, Input, OnInit } from '@angular/core';


declare  var Sketchfab:  any;

@Component({
  selector: 'vsf-ar-button',
  templateUrl: './ar-button.component.html',
  styleUrls: ['./ar-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ArButtonComponent implements OnInit {


  @Input() fallbackUrl : string
  @Input() src : string
  @Input() iosSrc: string
  @Input() sketchFabId: string
  @Output() sketchFabEvent = new EventEmitter<boolean>();

  isIOS: boolean
  isDisabled : boolean 
  isARQuickViewCandidate : boolean
  isSceneViewerCandidate : boolean

  constructor() {
  }

  ngOnInit(): void {

    const navigator = window.navigator

    const compat = {

      IS_AR_QUICKLOOK_CANDIDATE: (() => {

        this.isIOS = (/iPad|iPhone|iPod/.test(navigator.userAgent) && !(self as any).MSStream) ||
                      (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)

        const isWKebView = Boolean((window as any).webkit && (window as any).webkit.messageHandlers)

        if(this.isIOS){
            if(!isWKebView){            
                const tempAnchor = document.createElement('a');
                return Boolean(tempAnchor.relList && tempAnchor.relList.supports && tempAnchor.relList.supports('ar'));
            } else {
                return  Boolean(/CriOS\/|EdgiOS\/|FxiOS\/|GSA\/|DuckDuckGo\//.test(navigator.userAgent));
            }
        } else {
            return false;
        }
      })(),
      
      IS_SCENEVIEWER_CANDIDATE:
        /android/i.test(navigator.userAgent) &&
        !/firefox/i.test(navigator.userAgent) &&
        !/OculusBrowser/.test(navigator.userAgent),
    }

    this.isARQuickViewCandidate = compat.IS_AR_QUICKLOOK_CANDIDATE
    this.isSceneViewerCandidate = compat.IS_SCENEVIEWER_CANDIDATE

    console.log("*****")

    if(!((this.isARQuickViewCandidate || this.isSceneViewerCandidate) && this.sketchFabId)) {
        
      this.isDisabled = (this.isARQuickViewCandidate && !this.iosSrc) ||  ( this.isSceneViewerCandidate && !this.src ) || 
                        (!this.isARQuickViewCandidate && !this.isSceneViewerCandidate )
    }

  }

  clickARButton() {


    if(this.sketchFabId && this.isIOS) {
    
      // Normally, we would use ar-redirect here .. but, it seems to leave a popup window around - so, this is a workaround where we use API directly.
      this.sketchFabEvent.emit(true);
    
    } else {
    
      let href = "";

      const anchor = document.createElement("a");
  
      if(this.sketchFabId) {

        href = `https://sketchfab.com/models/${this.sketchFabId}/ar-redirect`

      } else if(this.isARQuickViewCandidate) {
          
        href = this.iosSrc
        anchor.appendChild(document.createElement("img"));
        anchor.rel = "ar";
      

      } else {
      
        href = `intent://arvr.google.com/scene-viewer/1.0?file=${this.src}&mode=ar_only`
        href +=
          `#Intent;scheme=https;` +
          `package=com.google.ar.core;` +
          `action=android.intent.action.VIEW;`;

          if (this.fallbackUrl) {
            href += `S.browser_fallback_url=${encodeURIComponent(this.fallbackUrl)};`;
        }

        href += `end;`;
      }
   
      anchor.setAttribute("href", href);
      anchor.click();
        
      document.removeChild(anchor);
    }
  }
}
