
export function getApplication(host: string): string {

    let application = ""

    switch(host) {
        case 'dashaus.devitsolutions.co.uk': {
            application = "DAS Haus"
            break;
        }
        case 'dasworld.devitsolutions.co.uk': {
            application = "DAS World";
            break;
        }
        default: {
            application = "DAS Lite";
            break;
        }
     }


     return application;
}
