import {gql} from 'apollo-angular';

export const GET_PINBOARD = gql`
    query GetPinboard($slug: String!) {
        collection(slug:$slug) {

		    description
		    id
            name
            
            productVariants {
                totalItems
                items {
                    id
                    name
                    price
                    product {
                        description
                        name
                        featuredAsset {
                            source
                        }

                        variants {

                            options {
                                code
                                name
                                group {
                                    name
                                    code
                                }
                            }                       
                        }

                        customFields {
                            modelUrl
                            arQuickLookUrl
                            sketchFabId
                            nfp
                            ntf
                            agent
                            agentDesc
                            
                        }
                    }   
                }
            }
        }
    }
`;
