<div class="header">
    <mat-chip class="large_chip" color="accent" selected>{{ collection?.name }}</mat-chip>
</div>



<p>
    <mat-form-field class="wrapper" appearance="outline">
        <mat-label>From</mat-label>
        <input matInput placeholder="Ex. Nigel Waller" [disabled]="true" value="Sender">

    </mat-form-field>
</p>

<p>
<mat-form-field class="wrapper" appearance="outline" >
    <mat-label>Message</mat-label>
    <textarea matInput  [disabled]="true">{{collection?.description | stripTags }}</textarea>
    </mat-form-field>
</p>

<div id="gallery" class="grid-container">

    <div *ngFor="let item of collection?.productVariants.items">

        <a (click)="onClick(item)" class="gallery__link">
            <img src='{{ item.product.featuredAsset.source }}' class="gallery__image" alt=''/>
        </a>

    </div>

</div>

<nav class="nav">

    <a href="#"  onclick="return false;" class="nav__link" >
        <mat-icon>edit_note</mat-icon>
        <span>Edit</span>
    </a>
    <a href="#"  onclick="return false;" class="nav__link" >
        <mat-icon>forward</mat-icon>
        <span>Forward</span>
    </a>
</nav>