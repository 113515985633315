
import { Component, Inject, AfterViewInit, OnInit, ViewChild, ElementRef } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { PinboardData } from '../gallery/gallery.component';
import { MatTabGroup, MatTabChangeEvent } from '@angular/material/tabs';

declare  var Sketchfab:  any;

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.css'],
})
export class PinboardWorkComponent implements AfterViewInit, OnInit {

  pinboard: PinboardData
 
  @ViewChild('sketchFabFrame') sketchFabFrame:ElementRef;
  @ViewChild('modelViewer') modelViewer:ElementRef;
  @ViewChild('tabGroup') tabGroup:MatTabGroup;
  @ViewChild('startAR') startAR:ElementRef;

  constructor(
    public dialogRef: MatDialogRef<PinboardWorkComponent>,
    @Inject(MAT_DIALOG_DATA) public pinboardData: PinboardData, 
  ) {
   this.pinboard = pinboardData
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  

  ngOnInit() {  }


  ngAfterViewInit() {

    if(this.sketchFabFrame) {

      // By default, the latest version of the viewer API will be used.
      var client = new Sketchfab( this.sketchFabFrame.nativeElement );

      client.init(  this.pinboardData.sketchFabId, {
        success: function onSuccess( api: any ){

            api.start();
        
            api.addEventListener( 'viewerready', function() {

              // Autostart AR 
              let element = document.getElementById("sketchFabFrame");
          
              if(element && element.getAttribute("startAR") == "yes") {
                api.startAR();

                element.removeAttribute("startAR")
              }
            
            } );


            let element = document.getElementById("startAR");
            
            if(element) {

              element.addEventListener('click', function() {
                api.startAR();
              });
            }

        },
        error: function onError() {
            console.log( 'Viewer error' );
        },
        autostart: 1,
        autospin:1,
        transparent:1,
        ui_infos:0,
        ui_inspector:0,
        ui_watermark_link:0,
        ui_watermark:0,
        ui_ar:0,
        ui_help:0,
        ui_settings:0,
        ui_vr:0,
        ui_fullscreen:0

      } );
    }

  }

  startSketchFabAR() {

    // Workaround for IOS
    this.sketchFabFrame.nativeElement.setAttribute("startAR", "yes");
    this.tabGroup.selectedIndex = 2
    this.startAR.nativeElement.click()
 
  } 

  tabChanged(tabChangeEvent: MatTabChangeEvent): void {
    
    if(this.modelViewer) {
      let src = this.modelViewer.nativeElement.src 
      
      this.modelViewer.nativeElement.src =  src  + "?bugfix=" + tabChangeEvent.index 
      
    }
  
  }
}


