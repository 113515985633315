import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle, SafeUrl } from '@angular/platform-browser';
import { gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { GET_DAS_COLLECTIONS } from '../../../common/graphql/documents.graphql';

import { getApplication } from '../../../common/utils/get-application';
import { GetDasCollectionsQuery, GetDasCollectionsQueryVariables } from '../../../common/generated-types';
import { DataService } from '../../providers/data/data.service';
type CollectionItem = GetDasCollectionsQuery['collections']['items'][number];
@Component({
    selector: 'vsf-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HomePageComponent implements OnInit {
    collections$: Observable<CollectionItem>;
    heroImage: SafeUrl;
    readonly placeholderProducts = Array.from({length: 12}).map(() => null);

    constructor(private dataService: DataService, private sanitizer: DomSanitizer) {
    }

    
    ngOnInit() {



        this.collections$ = this.dataService.query<GetDasCollectionsQuery, GetDasCollectionsQueryVariables>(GET_DAS_COLLECTIONS, 
            { options: {filter: { name: { eq: getApplication(location.hostname) }}}}
            ).pipe(
                map(({collections}) => collections.items[0] )
            )


        this.heroImage = this.getHeroImageUrl();
    }

    private getHeroImageUrl(): string {
        const {apiHost, apiPort} = environment;
        return `url('${apiHost}:${apiPort}/assets/preview//69/abstract_abstract_expressionism_abstract_painting_acrylic_paint_art_artistic_canvas_colorful-1568485.jpg')`;

    }

}

const GET_COLLECTIONS = gql`
    query GetCollections($options: CollectionListOptions) {
        collections(options: $options) {
            items {
                id
                name
                slug
                parent {
                    id
                    slug
                    name
                }
                featuredAsset {
                    id
                    preview
                }
            }
        }
    }
`;


const GET_TOP_SELLERS = gql`
    query GetTopSellers {
        search(input: {
            take: 8,
            groupByProduct: true,
            sort: {
                price: ASC
            }
        }) {
            items {
                productId
                slug
                productAsset {
                    id
                    preview
                }
                priceWithTax {
                    ... on PriceRange {
                        min
                        max
                    }
                }
                productName
            }
        }
    }
`;
